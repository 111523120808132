import {combineReducers} from 'redux';
import {ChatAccessRuleTypes} from 'mattermost-redux/action_types';

function last_key(state = '', action) {
    switch (action.type) {
    case ChatAccessRuleTypes.GET_CHAT_ACCESS_RULES_RECEIVED:
        return action.data.last_key
    default:
        return state;
    }
}

export default combineReducers({
    last_key,
});
