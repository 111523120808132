import {combineReducers} from 'redux';

function app(state = {}, action) {
    const nextState = {...state};
    var key;

    switch (action.type) {
        case 'SELECT_APP': {
            nextState.appId = action.data.id;
            return nextState;
        }
        case 'SELECT_APPS_PERMISSIONS': {
            nextState.permissions = action.data;
            return nextState;
        }
        case 'SELECT_TEAM_USERS_APPS_PERMISSIONS': {
            nextState.teamUserPermissions = action.data;
            return nextState;
        }
        default:
          return state;
    }
}

function arrangeApp(state = false, action) {
    switch (action.type) {
        case 'UPDATE_ARRANGE_APP_STATE': {
            return action.data;
        }
        default:
          return state;
    }
}

export default combineReducers({
    app,
    arrangeApp
});
