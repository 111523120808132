import {combineReducers} from 'redux';
import {ActionTypes} from 'utils/constants.jsx';


function getStoredTaskId(state = {}, action) {
    switch (action.type) {
        case ActionTypes.STORED_TASK_ID: {
            return action.data;
        }
        default:
          return state;
    }
}

function getAddTaskFormData(state = {}, action) {
    switch (action.type) {
        case ActionTypes.STORED_ADD_TASK_FORM_DATA: {
            return action.data;
        }
        default:
          return state;
    }
}

function isViewTasksNewLayoutView(state = false, action) {
    switch (action.type) {
        case ActionTypes.SET_TASKS_NEWLAYOUT_VIEW: {
            return action.data;
        }
        default:
          return state;
    }
}

function viewTasksNewLayoutTask(state = false, action) {
    switch (action.type) {
        case ActionTypes.SET_TASKS_NEWLAYOUT_TASK: {
            return action.data;
        }
        default:
          return state;
    }
}


export default combineReducers({
    getStoredTaskId,
    getAddTaskFormData,
    isViewTasksNewLayoutView,
    viewTasksNewLayoutTask,
});
