import {combineReducers} from 'redux';
import {ActionTypes} from 'utils/constants.jsx';

function contactMode(state = 0, action) {
    switch (action.type) {
    case ActionTypes.UPDATE_CONTACT_MODE:
        return action.mode;
    default:
        return state;
    }
}

function contactSelected(state = 0, action) {
    switch (action.type) {
    case ActionTypes.UPDATE_CONTACT_SELECTED:
        return action.contactId;
    default:
        return state;
    }
}

function contactUploadError(state = 0, action) {
    switch (action.type) {
    case ActionTypes.SET_CONTACT_ERROR_MODE:
        return action.mode;
    default:
        return state;
    }
}

function contactAssignList(state = 0, action) {
    switch (action.type) {
    case ActionTypes.SET_CONTACT_ASSIGN_LIST:
        return {appId: action.appId, recordId:action.recordId, contactIds: action.contactIds};
    default:
        return state;
    }
}

function contactAssignId(state = {}, action) {
    switch (action.type) {
    case ActionTypes.SET_CONTACT_ASSIGN_ID:
        return {
          ...state,
          [action.appId] : {recordId:action.recordId, contactId: action.contactId}
        }
    default:
        return state;
    }
}

function contactAdd(state = 0, action) {
    switch (action.type) {
    case ActionTypes.SET_CONTACT_ADD_STATE:
        return {
          ...state,
          add_button: action.status
        }
    case ActionTypes.SET_PROFILE_ID_FOR_ADD_CONTACT:
        return {
          ...state,
          chat_user_id: action.userId
        }
    case ActionTypes.SET_CONTACT_CR_INFO_FOR_ADD_CONTACT:
        return {
          ...state,
          cr_number: action.callerIdNumber,
          cr_name: action.callerIdName
        }
    default:
        return state;
    }
}

function storedContactSetting(state = 0, action) {
    switch (action.type) {
    case ActionTypes.UPDATE_CONTACT_SETTING:
        return action.contactSetting;
    default:
        return state;
    }
}

function uploadContactMode(state = false, action) {
    switch (action.type) {
    case ActionTypes.UPDATE_UPLOAD_CONTACT_MODE:
        return action.mode;
    default:
        return state;
    }
}

function deleteContactsMode(state = false, action) {
    switch (action.type) {
    case ActionTypes.DELETE_CONTACTS_MODE:
        return action.mode;
    default:
        return state;
    }
}

function deleteMultipleContactsMode(state = false, action) {
    switch (action.type) {
    case ActionTypes.DELETE_MULTIPLE_CONTACTS:
        return action.mode;
    default:
        return state;
    }
}

export default combineReducers({
    contactMode,
    contactSelected,
    contactAssignList,
    contactAssignId,
    contactAdd,
    storedContactSetting,
    contactUploadError,
    uploadContactMode,
    deleteContactsMode,
    deleteMultipleContactsMode,
});
