import {combineReducers} from 'redux';
import {UserTypes} from 'mattermost-redux/action_types';
import {ActionTypes} from 'utils/constants.jsx';


function getSwitchToNoteLink(state = {}, action) {
    const nextState = {...state};
    switch (action.type) {
        case ActionTypes.STORED_NOTE_LINK: {
            return action.data;
        }
        default:
          return state;
    }
}

function fullScreenMode(state = {}, action) {
    switch (action.type) {
        case ActionTypes.NOTE_FULL_SCREEN: 
            return action.data;
        default:
          return state;
    }
}

function defaultNoteBookId(state = '', action) {
    switch (action.type) {
        case ActionTypes.RECEIVED_DEFAULT_NOTE_BOOK: 
            return action.data;
        default:
          return state;
    }
}

function getAddNoteFormData(state = {}, action) {
    switch (action.type) {
        case ActionTypes.STORED_ADD_NOTE_FORM_DATA: {
            return action.data;
        }
        default:
          return state;
    }
}


export default combineReducers({
    getSwitchToNoteLink,
    fullScreenMode,
    defaultNoteBookId,
    getAddNoteFormData,
});
