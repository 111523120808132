import {combineReducers} from 'redux';

function reloadOptions(state = {}, action) {
    const nextState = {...state};

    switch (action.type) {
        case 'IFRAME_RELOAD_OPTIONS': {
            return action.data;
        }
        default:
          return state;
    }
}

export default combineReducers({
    reloadOptions,
});
