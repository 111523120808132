import {combineReducers} from 'redux';
import {ContactTypes} from 'mattermost-redux/action_types';

function last_key(state = '', action) {
    switch (action.type) {
    case ContactTypes.RECEIVED_CONTACT_COMM_HISTORY:
        return action.data.last_key
    default:
        return state;
    }
}

export default combineReducers({
    last_key,
});
