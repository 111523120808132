import {combineReducers} from 'redux';
import {ContactTypes} from 'mattermost-redux/action_types';

function last_key(state = '', action) {
    switch (action.type) {
    case ContactTypes.RECEIVED_CONTACTS_LIST:
        return action.data.last_key
    default:
        return state;
    }
}

function given_search(state = '', action) {
    switch (action.type) {
    case ContactTypes.RECEIVED_CONTACTS_LIST:
        return action.data.given_search
    default:
        return state;
    }
}

function asof(state = '', action) {
    switch (action.type) {
    case ContactTypes.RECEIVED_CONTACTS_LIST:
        return action.data.asof
    default:
        return state;
    }
}

function given_asof(state = '', action) {
    switch (action.type) {
    case ContactTypes.RECEIVED_CONTACTS_LIST:
        return action.data.given_asof
    default:
        return state;
    }
}

function new_data(state = '', action) {
    switch (action.type) {
    case ContactTypes.RECEIVED_CONTACTS_LIST:
        return action.data.new_data
    default:
        return state;
    }
}

function is_favorite(state = false, action) {
    switch (action.type) {
    case ContactTypes.RECEIVED_CONTACTS_LIST:
        return action.data.is_favorite
    default:
        return state;
    }
}

export default combineReducers({
    last_key,
    given_search,
    asof,
    given_asof,
    new_data,
    is_favorite,
});
